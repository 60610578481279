exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "iframe {\n  border: none !important;\n}\n.container___1VTct {\n  overflow-x: auto;\n}\n.innerContainer___2poRb {\n  min-height: 690px;\n  min-width: 1000px;\n}\n.paper___2oFO5 {\n  margin: 20px 0;\n  padding: 20px;\n  position: relative;\n}\n.paperContent___3JA1H {\n  padding: 0 25px;\n  position: relative;\n}\n.paperContent___3JA1H svg {\n  position: absolute;\n  left: 0;\n  top: 0;\n  color: #3071ad;\n}\n.closeBtn___gqePR {\n  position: absolute !important;\n  right: 0;\n  top: 0;\n}\n", "", {"version":3,"sources":["C:/agent5/vsts-agent-win-x64-2.153.2/_work/1917/s/src/Crayon.EndCustomerPortal/ClientApp/src/components/Reports/Reports.module.less"],"names":[],"mappings":"AAAA;EACE,wBAAwB;CACzB;AACD;EACE,iBAAiB;CAClB;AACD;EACE,kBAAkB;EAClB,kBAAkB;CACnB;AACD;EACE,eAAe;EACf,cAAc;EACd,mBAAmB;CACpB;AACD;EACE,gBAAgB;EAChB,mBAAmB;CACpB;AACD;EACE,mBAAmB;EACnB,QAAQ;EACR,OAAO;EACP,eAAe;CAChB;AACD;EACE,8BAA8B;EAC9B,SAAS;EACT,OAAO;CACR","file":"Reports.module.less","sourcesContent":["iframe {\n  border: none !important;\n}\n.container {\n  overflow-x: auto;\n}\n.innerContainer {\n  min-height: 690px;\n  min-width: 1000px;\n}\n.paper {\n  margin: 20px 0;\n  padding: 20px;\n  position: relative;\n}\n.paperContent {\n  padding: 0 25px;\n  position: relative;\n}\n.paperContent svg {\n  position: absolute;\n  left: 0;\n  top: 0;\n  color: #3071ad;\n}\n.closeBtn {\n  position: absolute !important;\n  right: 0;\n  top: 0;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "container___1VTct",
	"innerContainer": "innerContainer___2poRb",
	"paper": "paper___2oFO5",
	"paperContent": "paperContent___3JA1H",
	"closeBtn": "closeBtn___gqePR"
};